/**
 * Counter
 */

var tpl         = _.template(require('./templates/counter.html'));


module.exports  = {


    /**
     * Init
     *
     * @param {string} element class or id to make as currency
     */
    init: function(element) {
        this.bind(element);
        return this.render(element);
    },

    bind: function(element) {

        $('body').on('keyup focus paste', element, function() {

            var $input  = $(this);
            var max     = $input.attr('maxlength');
            var type    = $input.data('type') || 'characters';
            if (max === undefined) {
                console.log('The attr maxlength is not defined in field ' + $input.attr('name'));
                return false;
            }

            var current = $input.val().length;
            if (current >= max) {
                $input.val( $input.val().substr(0, max) );
            }

            var $el     = $(tpl({ max: max, current: current, text: type.charAt(0).toUpperCase() + type.slice(1)}));
            var $target = $input.parent().find('.wrap-field__counter').first();

            $target.replaceWith($el);

        });

    },

    /**
     * Bind element class or id to render html into password
     * @param string element
     */
    render: function(element) {

        var _this           = this;

        $(element).each(function() {

            var $input      = $(this);
            var max         = $input.attr('maxlength');
            var type        = $input.data('type') || 'characters';

            //
            // TODO make it for word
            //
            var current     = $input.val().length;
            var $el         = $(tpl({ max: max, current: current, text: type.charAt(0).toUpperCase() + type.slice(1)}));

            $el.insertAfter($input);

        });

    },

    char: function($input) {

    },

    word: function($input) {

    }

};
