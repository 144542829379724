
/*! Foundation-select https://github.com/colinduwe/foundation-select */
(function ($) {

	$.fn.foundationSelect = function($input) {

		var $el	= $input || this;

		// Check to see if custom dropdowns have already been drawn
		if (!$('.custom-dropdown-area').length || $input !== undefined) {

			// If custom dropdowns haven't been drawn, build and insert them
			return $el.each(function () {

				if ($input !== undefined) {
					$(this).parent().find('.custom-dropdown-area').remove();
				}

				var selectPrompt = '';
				var selectPosition = '';
				var selected = '';
				var translateClasses = '';
				var select = $(this);
				var selectId = select.attr('id');
				if(selectId === undefined) {
					selectId = 'fs-'+Date.now();
					select.attr('id', selectId);
				}
				var multiple = false;
				var multiple = select.prop('multiple') ? true : false;
				var options = '';
				if (select.data('prompt')) {
					selectPrompt = '<span class="default-label">' + select.data('prompt') + '</span>';
					if(select.data('prompt-clear') !== undefined) {
						options = '<li class="" data-prompt-item>' + selectPrompt + '</li>';
					} else {
						options = '<li class="disabled" data-prompt-item>' + selectPrompt + '</li>';
					}
				} else {
					selectPrompt = 'Choose...';
				}
				if (select.data('position')) {
					selectPosition = select.data('position');
				}
				select.find('option').each(function () {
					if ($(this).attr('selected')) {
						selected = 'selected';
						selectPrompt = "<div class='" + $(this).attr('class') + "'>" + $(this).html() + "</div>";
					}
					if ($(this).attr('class')) {
						translateClasses = $(this).attr('class') + ' ';
					}
					options += '<li data-value="' + this.value + '" class="' + translateClasses + selected + '"><span class="option-title">' + $(this).html() + '</span></li>';
					selected = '';
				});
				var newButton = '<div class="custom-dropdown-area" data-orig-select="#' + selectId + '"' + (multiple ? ' data-multiple="true"' : '') + '><button type="button" data-toggle="select-' + selectId + '" class="custom-dropdown-button float-left">' + selectPrompt + '</button> \
        <ul id="select-' + selectId + '" class="dropdown-pane custom-dropdown-options' + selectPosition + '" data-dropdown data-hover="true" data-hover-pane="true"> \
          ' + options + ' \
        </ul></div>';
				select.hide();
				select.after(newButton);
			});
		}
		;
	};

	// setup a listener to deal with custom dropdown clicks.
	$(document).on('click', '.custom-dropdown-area li', function () {

		var dropdown		= $(this).closest('.custom-dropdown-area');
		var origDropdown	= $(dropdown.data('orig-select'));
		var prompt			= origDropdown.data('prompt') ? origDropdown.data('prompt') : 'Choose...';
		var clear			= origDropdown.data('prompt-clear');

		// if has a promt as mark all
		if ($(this).data('prompt-item') !== undefined && clear !== undefined) {
			dropdown.find('li').removeClass('selected');
			dropdown.find('.custom-dropdown-button').html(prompt);
			origDropdown.val('').change();
			$('#' + dropdown.find('ul').attr('id')).foundation('close');
			return false;
		}

		if ($(this).hasClass('disabled')) {
			return false;
		}

		var multiple = dropdown.data('multiple') ? true : false;
		var text = "<div class='" + $(this).attr('class') + "'>" + $(this).find('.option-title').html() + "</div>";
		var value = $(this).data('value');
		var totalOptions = dropdown.find('li').not('.disabled').length;
		if (multiple) {
			$(this).toggleClass('selected');
			var selectedOptions = [];
			var selectedTitles = [];
			dropdown.find('.selected').each(function () {
				selectedOptions.push($(this).data('value'));
				selectedTitles.push($(this).find('.option-title').html());
			});
			origDropdown.val(selectedOptions).change();
			if (selectedOptions.length) {
				if (selectedOptions.length > 2) {
					dropdown.find('.custom-dropdown-button').html(selectedOptions.length + ' of ' + totalOptions + ' selected');
				} else {
					dropdown.find('.custom-dropdown-button').html(selectedTitles.join(', '));
				}
			} else {
				dropdown.find('.custom-dropdown-button').html(prompt);
			}
		} else {
			dropdown.find('li').removeClass('selected');
			$('#' + dropdown.find('ul').attr('id')).foundation('close');
			origDropdown.val(value).change();
			$(this).toggleClass('selected');
			dropdown.find('.custom-dropdown-button').html(text);
		}
	});

	$(document).on('reset', 'form', function () {
		if ($(this).children('.custom-dropdown-area').length) {
			$(this).find('.custom-dropdown-area').each(function () {
				var origDropdown = $($(this).data('orig-select'));
				var dropdown	= $(this);
				var multiple	= dropdown.data('multiple') ? true : false;
				var prompt		= '';
				dropdown.find('li').removeClass('selected');
				if (origDropdown.data('prompt')) {
					prompt = origDropdown.data('prompt');
				} else {
					origDropdown.find('option').each(function () {
						if ($(this).attr('selected')) {
							prompt = $(this).html();
							dropdown.find('li[data-value="' + this.value + '"]').addClass('selected');
						}
					});
					if (prompt === '') {
						prompt = 'Choose...';
					}
				}
				dropdown.find('.custom-dropdown-button').html(prompt);
			});
		}
	});

}(jQuery));