/**
 * Datepicker
 */
// Libs
var moment      = require('moment');

// Components
var Input       = require('components/forms/input');

module.exports  = {

    /**
     * Init
     *
     * @param {string} element class or id to make as datepicker
     */
    init: function(element) {
        return this.bind(element);
    },

    // TODO incomplete
    beforeRender: function(checkin, checkout) {

    },

    /**
     * Bind element class or id to datepicker
     * @param string element
     */
    bind: function(element) {

        var _this       = this;

        // Icon click
        $('body').on('click', '.input-group-label__pointer', function(e) {
            var $group   = $(this).parents('div:first');
            var $input   = $group.next().find(element).first();
            if($input.size() > 0) {
                $input.trigger('focus');
            }
        });

        $(element).each(function(i) {

            var $input      = $(this);
            var format      = $input.attr('data-format') || 'mm/dd/yyyy';
            var range       = $input.parents('.datepicker-range:first');
            var isCheckin   = $input.hasClass('datepicker-checkin');
            var isCheckout  = $input.hasClass('datepicker-checkout');
            var placeholder = $input.attr('placeholder');
            var cb          = $input.attr('data-onchange');
            if(placeholder !== undefined && placeholder.length < 1) {
                $input.attr('placeholder', format.toUpperCase());
            }
            var maxToday    = ($input.data('max-today') !== undefined) ? true : false;
            if(range.size() > 0) {
                if(isCheckin) {
                    $input.attr('data-validator', 'datepicker-checkin');
                }
                if(isCheckout) {
                    $input.attr('data-validator', 'datepicker-checkout');
                }
                if(range.find('[data-max-today]').size() > 0) {
                    maxToday    = true;
                }
            }

            var fdp;
            var nowTemp     = new Date();
            var now         = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

            console.log('fdatepicker', $($input).fdatepicker)

            fdp = $($input).fdatepicker({
                format: format,
                onRender: function (date) {
                    var este = $('.datepicker-checkin', $($input).parents('.datepicker-range')),
                    checkin  = moment(este.val(), 'MM-DD-YYYY').toDate();
                    if(!maxToday) {
                        if(isCheckin) {
                            return '';
                        }
                        return (isCheckout &&  date.valueOf() < checkin.valueOf()) ? 'disabled' : '';
                    }
                    return date.valueOf() > now.valueOf() || (isCheckout &&  date.valueOf() < checkin.valueOf()) ? 'disabled' : '';                        
                }
            });

            fdp.on('show', function () {
                console.log('show', $input);
                $input.closest('fieldset').toggleClass('focus', true);
            })
            fdp.on('hide', function () {
                console.log('hide', $input);
                $input.closest('fieldset').toggleClass('focus', false);
            })

            fdp.on('changeDate', function(ev) {

                // Current datepicker
                var datepicker = $(this);

                // If has checkin or checkout (range)
                if (datepicker.hasClass('datepicker-checkin')) {

                } else if (datepicker.hasClass('datepicker-checkout')) {

                }

                if(cb !== undefined) {
                    cb.apply(datepicker);
                }

            });

        });

    }

};
