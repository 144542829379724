/**
 * Input
 */

module.exports  = {

    /**
     * Return the value of one input
     * @param JQuery $input
     * @returns string
     */
    value: function($input) {
        if($input.attr('data-currency') !== undefined) {
            return $.trim($.number($input.val(), '2', '.', ''));
        } else {
            return $.trim($input.val());
        }
    },




    /**
     * Return a select populated with the data
     * @param $jquery $input
     * @param array data
     * @param Object fields
     * @returns null
     */
    dbSelect: function($input, data, fields, blank, value, cb) {
        //console.info(fields);
        if(!data) {
            return;
        }

        if(!fields) {
            fields  = {value: 'id', option: 'name'};
        }
        var options = '';
        var tmpSelect = blank || 'Select';
        if (blank || blank === undefined) {
            options = '<option value="">' + tmpSelect + '</option>';
        }

        var each = function(items, fEach, cb){
            var total = items.length,
                i     = 0,
                end   =  cb || function(){},
                next  = function(i, items) {
                    setTimeout(function() {
                        fEach(i, items[i]);
                        i++;
                        if(i< total){
                            next(i, items);
                        }else{
                            cb();
                        }
                    }, 0);
                };
            next(i, items);
        };
        $input.empty();
        $input.html('<option value="-1" selected="selected">Loading...</option>');
        //console.info(data);

        each(data, function(i, j){
            var row     = data[i];
            var pkValue = row[fields.value] || '';
            var option;

            if(Array.isArray(fields.option)) {
                var tmpOption   = [];
                fields.option.forEach(function(k) {
                    tmpOption.push(row[k] || '');
                });
                option  = tmpOption.join(' ');
            } else {
                option  = row[fields.option] || '';
            }

            if ((pkValue || pkValue === '0') && option) {
                var selected    = (pkValue === value) ? 'selected="selected"' : '';
                options += '<option value="' + pkValue + '" '+selected+'>' + option + '</option>';
            }
        }, function(){
            $input.empty();
            $input.html(options);
			if(typeof cb === 'function') {
				setTimeout(function() {
					cb();
				}, 50);
			}
        });

    },

    /**
     * Remove input error
     * @param JQuery Object object
     */
    valid: function($input, timeout) {
        setTimeout(function() {
            $input.parents('div:first').find('label').removeClass('is-invalid-label');
            $input.parents('div:first').find('.form-error').removeClass('is-visible');
            $input.removeAttr('data-invalid').removeAttr('aria-invalid').removeClass('is-invalid-input');
        }, (timeout > 0) ? timeout : 500);
    },

    /**
     * Show input error
     * @param JQuery Object object
     */
    invalid: function($input, timeout) {
        setTimeout(function() {
            $input.parents('div:first').find('label').addClass('is-invalid-label');
            $input.parents('div:first').find('.form-error').addClass('is-visible');
            $input.attr('data-invalid', '').attr('aria-invalid', 'true').addClass('is-invalid-input');
            $input.parents('form:first').attr('data-invalid', '');
        }, (timeout > 0) ? timeout : 500);
    },


    /**
     * Remove input error
     * @param JQuery Object object
     */
    validInput: function($input, timeout) {
        this.valid($input, timeout);
    },

    /**
     * Show input error
     * @param JQuery Object object
     */
    invalidInput: function($input, timeout) {
        this.invalid($input, timeout);
    },

    /**
     * Add required parameter
     * @param JQuery Object object
     */
    addRequired: function($input) {
        $input.attr('required');
    },

    /**
     * Remove required parameter
     * @param JQuery Object object
     */
    removeRequired: function($input) {
        $input.removeAttr('required');
    }


};
