/**
 * DASHBOARD
 */

var Api = app.apiAjax;

const config = require('baseApp/config');
var moment = require('moment');
// var Maps = require('components/maps/density');

var Zurb = require('components/foundation');

// Libs
// var moment = require('moment');

// Global
var ui = require('sharedComponents/js/ui');

// Utility
// var DateRange = require('../utils/date');

// Components
const Export = require('components/export');
var Form = require('components/forms/form');
var Input = require('components/forms/input');
var Flash = require('components/flash');
var Pagination = require('components/pagination/pagination');

// Template
const filterDateTpl = _.template(require('components/templates/modals/filter-date.html'));
const accountRowTpl = _.template(require('../templates/account-row.html'));
const stateRowTpl = _.template(require('../templates/state-row.html'));
const optionTpl = _.template(require('../templates/option-row.html'));

module.exports = {

    //
    // INIT DASHBOARD
    //
    init: function () {

        const _this = this;

        // Bind Events
        this.bind();

        _this.loadCountries(() => {
            _this.loadStates(() => {
                _this.onCountryChange(true);
            });
        });

        // Load
        return this.load();
    },

    //
    // BIND EVENTS
    //
    bind: function () {

        var _this = this;

        // // Filter Date
        // $('body').on('change', '[name="dashboard[filter_dates]"]', function (e) {
        //     var custom = $(this).find('option:selected').attr("data-filter-custom");
        //     var scope = $(this).parents('.app-header').data('scope');
        //     if (custom !== undefined) {
        //         DateRange.open($(this), 'modal-filter-dashboard', scope);
        //     } else {
        //         $(this).parent().find('.filter-data').addClass('hide');
        //         _this.load();
        //     }

        // });
        // Filter Date
        $('body').on('change', '[name="dashboard[filter_dates]"]', function (e) {
            _this.openFilterDate($(this));
        });
        // Send Filter Data
        $('body').on('submit', '#modal-filter-data form', function (e) {
            e.preventDefault();
            _this.setFilterDate($(this));
            return false;
        });

        // // Send Filter Date
        // $('body').on('submit', '#modal-filter-dashboard', function (e) {

        //     e.preventDefault();
        //     DateRange.close($(this), function (scope) {
        //         _this.load(scope);
        //     });
        //     return false;

        // });

        // Change filter data
        $('body').on('click', '.filter-data', function (e) {
            $(this).parent().find('select').trigger('change');
        });


        
        // Filter status
        $('body').on('change', '[name="dashboard[filter_countries]"], [name="dashboard[filter_state]"], [name="dashboard[filter_general]"]', function(e) {
            _this.load();
        });

        // FILTER 
        $('select[name="dashboard[filter_countries]"]').change(() => {
            _this.onCountryChange(false);
        });


        Export.bind({
            trigger: '.app-header__actions-export', 
            endpoint: 'dashboard', 
            getFilter: _this.filter.bind(_this)
        });
    },


    openFilterDate: function ($el) {

        var _this = this;
        var custom = $el.find('option:selected').attr("data-filter-custom");

        if (custom !== undefined) {
            // Show span
            $el.parent().find('.filter-data').removeClass('hide');
            // Show modal
            var modal = ui.$createModal('custom', {
                id: 'modal-filter-data',
                title: 'Filter Users:',
                template: filterDateTpl
            });
            // Bind form
            Form.init(function () {
                modal.find('input').first().trigger('focus');
            });
        } else {
            $el.parent().find('.filter-data').addClass('hide');
            _this.load();
        }

    },

    setFilterDate: function ($form) {

        var _this = this;

        // Date as format MM/DD/YY
        var start = moment($('.datepicker-checkin', $form).val(), 'MM/DD/YYYY').format('MM/DD/YY');
        var end = moment($('.datepicker-checkout', $form).val(), 'MM/DD/YYYY').format('MM/DD/YY');

        // Date as format YYYY-MM-DD
        var mstart = moment($('.datepicker-checkin', $form).val(), 'MM/DD/YYYY').format('YYYY-MM-DD');
        var mend = moment($('.datepicker-checkout', $form).val(), 'MM/DD/YYYY').format('YYYY-MM-DD');

        // Set the span
        $('[name="dashboard[filter_dates]"]').parent().find('.filter-data').removeClass('hide').html(start + ' - ' + end);
        $('[name="dashboard[filter_dates]"]').parent().find('.filter-data').attr('data-filter', mstart + '|' + mend);

        // Set value to select
        $('[name="dashboard[filter_dates]"]').find('[data-filter-custom]').attr('value', mstart + '|' + mend);

        $('#modal-filter-data').foundation('close');

        _this.load();

    },



    loadCountries: function (done) {
        const _this = this;
        // Countries
        Api.request('countries')
          .done(function (r) {
            $('select[name="dashboard[filter_countries]"]').prop('disabled', !r.data || r.data.length === 0).html(optionTpl({ locations: r.data || [], current: _this.market ? _this.market.country : null, defaultName: '' }));
            _this.onCountryChange(true);
    
            done && done();
          })
          .fail(function () {
            $('select[name="dashboard[filter_countries]"]').prop('disabled', true).html(optionTpl({ locations: [], current: '', defaultName: '' }));
          });
      },
    
      loadStates: function (done) {
        const _this = this;
    
        const selectedState = $('select[name="dashboard[filter_states]"]').val() || (_this.market ? _this.market.state : null)
        // States
        Api.request('states')
          .done(function (r) {
            $('select[name="dashboard[filter_states]"]').prop('disabled', !r.data || r.data.length === 0).html(optionTpl({ locations: r.data || [], current: _this.market ? _this.market.state : null, defaultName: '' }));
    
            done && done();
          })
          .fail(function () {
            $('select[name="dashboard[filter_states]"]').prop('disabled', true).html(optionTpl({ locations: [], current: '', defaultName: '' }));
          });
      },
    
    
      onCountryChange: function (initial = false) {
        const selectedCountry = $('select[name="dashboard[filter_countries]"]').val()
        const isUSA = parseInt(selectedCountry) === 230
        const disabled = (!isUSA || selectedCountry === '' || $('select[name="dashboard[filter_states]"] option').length === 1);
        $('select[name="dashboard[filter_states]"]').prop('disabled', disabled).prop('required', !disabled)
    
        if (!isUSA) {
          $('select[name="dashboard[filter_states]"]').val(52) // Force to OTHER
        }
      },




    //
    // FILTER
    //
    filter: function () {

        var filter = [];

        var date = $('select[name="dashboard[filter_dates]"]').val();
        if (date) {
            filter.push("date=" + date);
        }

        var country = $('select[name="dashboard[filter_countries]"]').val();
        if (country) {
            filter.push("country=" + country);
        }

        var state = $('select[name="dashboard[filter_state]"]').val();
        if (state) {
            filter.push("state=" + state);
        }

        var general = $('select[name="dashboard[filter_general]"]').val();
        if (general) {
            filter.push("general=" + general);
        }

        filter = filter.join("&");

        return filter;

    },

    //
    // LOAD
    //
    load: function () {
        $('.app__veil').addClass('active');

        const _this = this;
        let filter = this.filter();

        Api.request('dashboard?' + filter).done(function (r) {

            $('.app__veil').removeClass('active');

            if (r.success) {
                $('.app-header__stats-registration').html($.number(r.data.countRegistrations || 0, 0, '', ','));
                $('.app-header__stats-basics').html($.number(r.data.countBasics || 0, 0, '', ','));
                $('.app-header__stats-pro').html($.number(r.data.countPros || 0, 0, '', ','));
                $('.app-header__stats-accounts').html($.number(r.data.countMarkets || 0, 0, '', ','));
                $('.app-header__stats-completion').html($.number(r.data.completion || 0, 0, '', ',') + '%');
                $('.app-header__stats-revenue').html('$' + $.number(r.data.revenue || 0, 0, '', ','));

                // TOP ACCOUNTS
                const topAccounts = _.map(r.data.topMarkets, function (item, index) {
                    item.index = index;
                    return item;
                });

                $('#app-container__account_list').html(accountRowTpl({ items: (topAccounts) || [] }));


                // TOP STATE ACCOUNTS
                const topStateAccounts = _.map(r.data.topStatesAccounts, function (item, index) {
                    item.index = index;
                    return item;
                });
                $('#app-container__state_account_list').html(stateRowTpl({ items: (topStateAccounts) || [] }));

                const topStateRegistrations = _.map(r.data.topStatesRegistrations, function (item, index) {
                    item.index = index;
                    return item;
                });
                $('#app-container__state_registration_list').html(stateRowTpl({ items: (topStateRegistrations) || [] }));

                const topStateCompletions = _.map(r.data.topStatesCompletions, function (item, index) {
                    item.index = index;
                    return item;
                });
                $('#app-container__state_completion_list').html(stateRowTpl({ items: (topStateCompletions) || [] }));

                const topStateRevenue = _.map(r.data.topStatesRevenue, function (item, index) {
                    item.index = index;
                    return item;
                });
                $('#app-container__state_revenue_list').html(stateRowTpl({ items: (topStateRevenue) || [] }));
            }
        }).fail((error) => {
            console.error(error);
            $('.app__veil').removeClass('active');
        });
    }

};
