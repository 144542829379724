/**
 * Abide Custom Validations
 */

var moment      = require('moment');
var Input       = require('components/forms/input');

module.exports  = {

    patterns: {
        // Only positives number
        'pint': /^[0-9]+$/,

        // Year format
        'year': /^(19|20)\d\d/,

        // Month format
        'month': /^(0[1-9]|1[012])/,

        // Day format
        'day': /^(0[1-9]|[12][0-9]|3[01])/,

        // Currency format 1.00
        'currency': /^((\d+)|(\d{1,3})(\,\d{3}|)*)(\.\d{2}|)$/,

        // zipcode
        'zipcode': /^[A-Z0-9-\.\s]{3,15}$/i
    },

    validators: {

        'dob': function($el, required, parent) {

            if(!$el.val()) { // If not has val but is required
                return (required) ? false : true;
            }

            var $dob    = $el.parent();
            var month   = Input.value($dob.find('[pattern="month"]'));
            var day     = Input.value($dob.find('[pattern="day"]'));
            var year    = Input.value($dob.find('[pattern="year"]'));

            if(!year || !month || !day ) {
                return false;
            }

            var age     = 0;
            var min     = 21;
            var today   = new Date();

            if (month > 12 || day > 31) {
                return false;
            }
            if(month < 1 || day < 1 || year < 1 || month === '00' || day === '00' || year === '0000') {
                return false;
            }

            var todayYear   = today.getFullYear();
            var todayMonth  = today.getMonth();
            var todayDay    = today.getDate();
            age             = todayYear - year;

            if (todayMonth < month - 1) {
                age--;
            }
            if (month - 1 === todayMonth && todayDay < day) {
                age--;
            }

            return (age < min) ? false : true;
        },

        'dateDOB': function($el, required, parent) {

            var $dob    = $el.parent();
            var month   = Input.value($dob.find('[pattern="month"]'));
            var day     = Input.value($dob.find('[pattern="day"]'));
            var year    = Input.value($dob.find('[pattern="year"]'));

            var hasValue= (month.length === 0 && day.length === 0 && year.length === 0) ? false : true;

            if(!hasValue) { // If not has val but is required
                return (required) ? false : true;
            }

            if(!year || month.length < 2 || day.length < 2) {
                return false;
            }

            return (moment(year+'-'+month+'-'+day, 'YYYY-MM-DD')).isValid();

        },

        // Datepicker Valid Date
        'datepicker-future': function($el, required, parent) {

            if(!$el.val()) { // If not has val but is required
                return (required) ? false : true;
            }

            var format      = ($el.attr('data-format') || 'MM/DD/YYYY').toUpperCase();
            var today       = moment().format('X');
            if(!moment($el.val(), format).isValid()) {
                return false;
            }
            var date        = moment($el.val() + ' 23:59:59', format +' HH:mm:ss').format('X');
            return (date >= today) ? true : false;

        },

        // Datepicker Checkin
        'datepicker-checkin': function($el, required, parent) {

            if(!$el.val()) { // If not has val but is required
                return (required) ? false : true;
            }

            var container   = $el.parents('.datepicker-range:first');
            var format      = ($el.attr('data-format') || 'MM/DD/YYYY').toUpperCase();
            var checkin     = $el;
            var checkout    = container.find('.datepicker-checkout:first');

            // Values
            var valCheckin  = checkin.val()     ? moment(checkin.val(), format)     : '';
            var valCheckout = checkout.val()    ? moment(checkout.val(), format)   : '';

            // Boolean Valid
            var valid       = true;

            // If has checkout
            if (checkout.size() > 0 && valCheckout) {
                if( valCheckin > valCheckout ) {
                    valid   = false;
                } else if(!valCheckout) {
                    setTimeout(function() {
                        container.find('.datepicker-checkout:first').trigger('focus');
                    }, 500);
                }
            } else if(container.size() > 0 && checkout.size() === 0) { // If not exist
                console.log("Missing the datepicker-checkout class into datepicker-range");
            }

            return valid;

        },

        // Datepicker Checkout
        'datepicker-checkout': function($el, required, parent) {

            if(!$el.val()) { // If not has val but is required
                return (required) ? false : true;
            }

            var container   = $el.parents('.datepicker-range:first');
            var format      = ($el.attr('data-format') || 'MM/DD/YYYY').toUpperCase();
            var checkin     = container.find('.datepicker-checkin:first');
            var checkout    = $el;

            // Values
            var valCheckin  = checkin.val()     ? moment(checkin.val(), format)     : '';
            var valCheckout = checkout.val()    ? moment(checkout.val(), format)    : '';

            // Boolean Valid
            var valid       = true;

            // If has checkout
            if (checkin.size() > 0 && valCheckin) {
                if( valCheckin > valCheckout ) {
                    valid   = false;
                }
            } else if(container.size() > 0 && checkin.size() === 0) { // If not exist
                console.log("Missing the datepicker-checkin class into datepicker-range");
            }

            return valid;

        },

        'multi-email': function($el, required, parent) {

            // parameter 1 is jQuery selector
            if(!$el.val()) { // If not has val but is required
                return (required) ? false : true;
            }

            var valid   = true;
            if($el.val()) {
                var emails  = $el.val().split(',');
                var pattern = Foundation.Abide.defaults.patterns.email;
                $.each(emails, function(i, j) {
                    if(!pattern.test( $.trim(emails[i]) )) {
                        valid   = false;
                        return false;
                    }
                });
            }

            return valid;

        }
    }

};
