const S = require('string');
const template = _.template(require('./templates/main.html'));


module.exports  = {

  render: function($element, conf) {

    $element = $($element);

    conf = _({
      baseUrl: '/',
      pages: 5,
      currentPage: 1,
      perPage: 10,
      maxInRow: 10,
      extraParams: ''
    })
    .extend(conf);

    conf.maxInRow = conf.maxInRow < 10 ? 10 : conf.maxInRow;

    conf.url = conf.baseUrl +'?'+
      (conf.extraParams ? S(conf.extraParams).ensureRight('&') : '') +
      'per_page='+ conf.perPage +'&page=';

    conf.prevUrl = conf.url + (conf.currentPage - 1);
    conf.nextUrl = conf.url + (conf.currentPage + 1);
    conf.internItems = '';

    const currentPage = '<li class="current"><span class="show-for-sr">You\'re on page</span> '+ conf.currentPage +'</li>';
    const ellipsis = '<li class="ellipsis" aria-hidden="true"></li>';
    const link = (url, i) => '<li><a href="'+ url +'" aria-label="Page '+ i +'" data-page="'+ i +'" data-per-page="'+ conf.perPage +'">'+ i +'</a></li>';

    // The number of items in within the maximun number allowed in the row.
    if (conf.pages <= conf.maxInRow) {
      for (let i = 1; i <= conf.pages; i++) {
        const url = conf.url + i;
        conf.internItems += conf.currentPage === i ? currentPage : link(url, i);
      }
    } else {

      /**
      pages = 22
      maxInRow = 10
      current = 3
      1 2 [3] 4 5 (6 7 8 9 10 11 12 13 14 15 16 17 18) 19 20 21 22
      */
      if (conf.currentPage < conf.maxInRow - 3) {

        const breakpoint1 = conf.currentPage + 3;
        const breakpoint2 = conf.pages - Math.floor(conf.maxInRow/2) + conf.currentPage - 2;

        for (let i = 1; i <= conf.pages; i++) {

          const url = conf.url + i;

          if (i < breakpoint1) {
            conf.internItems += conf.currentPage === i ? currentPage : link(url, i);
          }
          else if (i === breakpoint1) {
            conf.internItems += ellipsis;
            i = breakpoint2;
          }
          else {
            conf.internItems += link(url, i);
          }
        }
      }

      /*
      1 2 3 4 5 (6 7 8 9 10) 11 [12] 13 (14 15 16 17 18) 19 20 21 22
      */
      else if (conf.currentPage >= conf.maxInRow - 3 &&
      conf.currentPage <= conf.pages - conf.maxInRow + 5) {

        conf.internItems += link(conf.url + 1, 1);
        conf.internItems += link(conf.url + 2, 2);

        conf.internItems += ellipsis;

        conf.internItems += link(conf.url + (conf.currentPage - 1), conf.currentPage - 1);
        conf.internItems += currentPage;
        conf.internItems += link(conf.url + (conf.currentPage + 1), conf.currentPage + 1);

        conf.internItems += ellipsis;

        conf.internItems += link(conf.url + (conf.pages - 2), conf.pages - 2);
        conf.internItems += link(conf.url + (conf.pages - 1), conf.pages - 1);
      }

      /*
      pages = 22
      maxInRow = 10
      current = 18
      1 2 (3 4 5 6 7 8 9 10 11 12 13 14 15) 16 17 [18] 19 20 21 22
      */
      else {
        const breakpoint1 = Math.ceil(conf.maxInRow/2) - (conf.pages - conf.currentPage) + 2;
        const breakpoint2 = conf.currentPage - 3;

        for (let i = 1; i <= conf.pages; i++) {

          const url = conf.url + i;

          if (i < breakpoint1) {
            conf.internItems += link(url, i);
          }
          else if (i === breakpoint1) {
            conf.internItems += ellipsis;
            i = breakpoint2;
          }
          else {
            conf.internItems += conf.currentPage === i ? currentPage : link(url, i);
          }
        }
      }
    }

    const html = template(conf);
    $element.html(html);
  }

};
