/**
 * Currency
 */


module.exports  = {

    /**
     * Init
     *
     * @param {string} element class or id to make as currency
     */
    init: function(element) {
        return this.bind(element);
    },

    /**
     * Bind element class or id to currency
     * @param string element
     */
    bind: function(element) {

        // Bind elements
        $(element).each(function() {
            $(this).number(true, 2, '.', '');
        });

    }

};
