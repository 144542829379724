/**
 * Wysiwyg
 */

module.exports  = {

    /**
     * Init
     *
     * @param {string} element class or id to make as datepicker
     */
    init: function(element) {
        return this.bind(element);
    },

    bind: function(element) {

        this.tinymce( $(element).not('.tinymce-basic, .tinymce-small'));

        this.basic( $(element).filter('.tinymce-basic'));

        this.small( $(element).filter('.tinymce-small') );

    },


    tinymce: function(element) {
        $(element).each(function() {

            var este    = $(this);
            var height  = parseInt(este.css('height'));
            $(this).tinymce({
                plugins: ["link textcolor colorpicker textpattern paste" ],
                setup: function (editor) {
                    editor.on('change', function () {
                        tinymce.triggerSave();
                    });
                },
                menubar: false,
                statusbar: false,
                paste_as_text: true,
                skin : 'barsmarts',
                height : height,
                force_br_newlines : true,
                force_p_newlines : false,
                forced_root_blocks: false,
                toolbar1: "undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link forecolor | fontsizeselect",
                fontsize_formats: "0.675rem 0.75rem 0.875rem 1rem 1.125rem 1.2rem 1.3rem 1.5rem 1.75rem 2rem 2.25rem 2.5rem"
            });

        });
    },

    basic: function(element) {
        $(element).each(function() {

            var este    = $(this);
            var height  = parseInt(este.css('height'));
            $(this).tinymce({
                plugins: ["link textcolor colorpicker textpattern paste" ],
                setup: function (editor) {
                    editor.on('change', function () {
                        tinymce.triggerSave();
                    });
                },
                menubar: false,
                statusbar: false,
                paste_as_text: true,
                skin : 'barsmarts',
                height : height,
                force_br_newlines : true,
                force_p_newlines : false,
                forced_root_blocks: false,
                toolbar1: "bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link forecolor ",
                fontsize_formats: "0.675rem 0.75rem 0.875rem 1rem 1.125rem 1.2rem 1.3rem 1.5rem 1.75rem 2rem 2.25rem 2.5rem"
            });

        });
    },

    small: function(element) {
        $(element).each(function() {

            var este    = $(this);
            var height  = parseInt(este.css('height'));
            $(this).tinymce({
                plugins: ["link textcolor colorpicker textpattern paste" ],
                setup: function (editor) {
                    editor.on('change', function () {
                        tinymce.triggerSave();
                    });
                },
                menubar: false,
                statusbar: false,
                paste_as_text: true,
                skin : 'barsmarts',
                height : height,
                force_br_newlines : true,
                force_p_newlines : false,
                forced_root_blocks: false,
                toolbar1: "bold italic | forecolor",
                fontsize_formats: "0.675rem 0.75rem 0.875rem 1rem 1.125rem 1.2rem 1.3rem 1.5rem 1.75rem 2rem 2.25rem 2.5rem"
            });

        });
    }


};
