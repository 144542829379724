/**
 * Form
 */

// Libs
var moment      = require('moment');

// Components
var Datepicker  = require('components/forms/datepicker');
var Timepicker  = require('components/forms/timepicker');
var Input       = require('components/forms/input');
var Password    = require('components/forms/password');
var Currency    = require('components/forms/currency');
var Tiny        = require('components/forms/tiny');
var Counter     = require('components/forms/counter');

// Mix
var Flash       = require('components/flash');

module.exports  = {

    init: function(cb) {

        setTimeout(function() {

            // Bind datepicker
            Datepicker.init('[data-datepicker]');

            // Bind Timepicker
            Timepicker.init('[data-timepicker]');

            // Tinymce
            Tiny.init('[data-tinymce]');

            // Currency
            Currency.init('[data-currency]');

            // Password
            Password.init('[type="password"]');

            // Counter
            Counter.init('[data-counter]');

            // Callback
            if(cb) {
                cb();
            }

        }, 500);

    },

    get: function($form, field) {

        var data    = $form.find(':input').filter(function () {
            if($(this).data('currency') !== undefined) {
                return $.number( this.value, 2, '.', '' );
            }
            return $.trim(this.value);
        }).serializeJSON();

        return (field) ? data[field] : data;

    },

    autoload: function(scope, values) {
        // Each values
        $.each(values, function(key, value) {

            //
            // key: value
            //
            // key: {
            //     id: value (id is required)
            // }
            //
            // Input: name="scope[key]" value="value"
            //

            if(value === null) {
                value   = '';
            }

            if(typeof value === 'object' && value.id) {
                value       = value.id;
            }

            var $input = null;
            if(scope){
              $input  = $('[name="'+ scope +'['+ key +']"]');
            }else{
              $input  = $('[name="'+key+'"]');
            }


            // If exist
            if($input.size() > 0 ) {
                // is checkbox
                if ($input.is(':checkbox')) {
                    (value) ? $input.prop('checked', true) : $input.prop('checked', false);
                } else {

                    if($input.data('datepicker') !== undefined) {
                        var tmpFormat   = $input.attr('data-format') || 'MM/DD/YYYY';
                        if(value.indexOf("Z") > 0) {
                            value           = (value) ? moment(value).format(tmpFormat) : '';
                        } else if(value.length > 10) {
                            value           = (value) ? moment(value, 'YYYY-MM-DD hh:ii:ss').format(tmpFormat) : '';
                        } else {
                            value           = (value) ? moment(value, 'YYYY-MM-DD').format(tmpFormat) : '';
                        }
                    }

                    if($input.data('currency') !== undefined) {
                        value               = (value)   ? $.number(value, 2, '.', '' ) : '0';
                    }

                    $input.val(value);
                }
            }

        });
    },

    utils: {
        bindTogether: function (inputNames) {
            $.each(inputNames, function (index, inputName) {
                $(`input[name="${inputName}"]`).change(() => {
                    const val = $(`input[name="${inputName}"]`).val();
                    $.each(inputNames, function (confirmIndex, confirmInputName) {
                        if (confirmInputName !== inputName) {
                            $(`input[name="${confirmInputName}"]`).attr('required', val !== '');
                        }
                    })
                })
            })
        },
    },

};
