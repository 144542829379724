var utils       = require('sharedComponents/js/utils');
var router      = new utils.Router();

// Components
var Zurb        = require('components/foundation');
var Flash       = require('components/flash');
var Form        = require('components/forms/form');

// Modules
var Dashboard   = require('./dashboard/dashboard');

// Start Router
$(document).ready(function() {

    Zurb.init();

    // Messages
    Flash.init();

    // Init Dashboard
    Dashboard.init();

});

// Public Debug
window.app.dashboard = { dashboard: Dashboard };
