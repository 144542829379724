const Api = app.apiAjax;
const Flash = require('components/flash');

module.exports = {

  bind({ trigger, endpoint, getFilter }) {
    const _this = this;

    trigger = trigger ? trigger : '.app-header__actions-export';
  
    // Export
    $('body').on('click', trigger, function (e) {
      e.preventDefault();
      _this.export({ trigger, endpoint, getFilter })

      return false;
    });
  },

  export({ trigger, endpoint, getFilter }) {
    $(trigger).prop('disabled', true);

    let filter = (getFilter && getFilter()) || '';
    filter = (filter ? filter + '&' : '') + 'export=true'

    Api.request(endpoint + '?' + filter).done(function (r) {
      if (r.success) {
        $(trigger).prop('disabled', false);

        if (r.data.rows > 0) {
          const url = r.data.url;

          console.log({url});
          window.location = url;
        } else {
          Flash.error('There are no results for your request');
        }
        
      }
    }).fail((err) => {
      console.error(err);
      $(trigger).prop('disabled', false);
    });
  }
};