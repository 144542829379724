/**
 * Timepicker
 */


module.exports  = {

    /**
     * Init
     *
     * @param {string} element class or id to make as timepicker
     */
    init: function(element) {
        return this.bind(element);
    },

    /**
     * Bind element class or id to timepicker
     * @param string element
     */
    bind: function(element) {

        // Icon click
        $('body').on('click', '.input-group-label__pointer', function(e) {
            var $group   = $(this).parents('div:first');
            var $input   = $group.next().find(element).first();
            if($input.size() > 0) {
                $input.trigger('focus');
            }
        });

        // Bind elements
        $(element).each(function() {

            var step    = $(this).attr('data-step') || 30;
            var format  = $(this).attr('data-format') || 'h:i A';
            $(this).timepicker({'step': step, 'timeFormat': format});

        });

    }

};
