/**
 * Foundation
 */

var _           = require('underscore');
var custom      = require('components/forms/validations');
require('components/foundation.select');

module.exports  = {

    /**
     * Init
     */
    init: function() {

		this.select();

    },

    /**
     * Custom utility for abide
     */
    abide: function() {

        // Current
        var patterns                            = Foundation.Abide.defaults.patterns;
        var validators                          = Foundation.Abide.defaults.validators;

        // Merged
        Foundation.Abide.defaults.patterns      = _.extend(patterns, custom.patterns);
        Foundation.Abide.defaults.validators    = _.extend(validators, custom.validators);

    },

	select: function($input) {

		$('select[multiple]').foundationSelect($input);
		setTimeout(function() {
			$(document).foundation();
		}, 100);

	}

};
